import React, { useState, useEffect, useRef } from 'react';
import './App.css';

// PoliticianSelector Component
const PoliticianSelector = ({ politicians, selectedPoliticians, onSelect, selectionError }) => {
  return (
    <div className="active-politicians">
      <h2>Select Politicians</h2>
      {selectionError && (
        <div className="selection-error">
          {selectionError}
        </div>
      )}
      <div className="selection-guide">
        {selectedPoliticians.length === 0 && (
          <div className="guide-text">Select 1 or 2 politicians</div>
        )}
        {selectedPoliticians.length === 1 && (
          <div className="guide-text">1v1 Roast Mode Ready!</div>
        )}
        {selectedPoliticians.length === 2 && (
          <div className="guide-text">Roast Battle Mode Ready!</div>
        )}
      </div>
      <div className="politician-grid">
        {Object.entries(politicians).map(([key, pol]) => (
          <button
            key={key}
            onClick={() => onSelect(key)}
            className={`politician-image-button ${
              selectedPoliticians.includes(key) ? 'selected' : ''
            }`}
          >
            <div className="politician-image-container">
              <img 
                src={pol.avatar.replace('.jpg', '-war.jpg')} 
                alt={pol.name}
                className="politician-avatar"
              />
              <div className="politician-name">{pol.name.split(' ')[0]}</div>
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};

// App Component
function App() {
  // State Variables
  const [posts, setPosts] = useState([]);
  const [topic, setTopic] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedPoliticians, setSelectedPoliticians] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [userInput, setUserInput] = useState('');
  const [isChatMode, setIsChatMode] = useState(false);
  const [battleRound, setBattleRound] = useState(1);
  const [battlePhase, setBattlePhase] = useState(null);
  const [previousExchanges, setPreviousExchanges] = useState([]);
  const [isBattleComplete, setIsBattleComplete] = useState(false);
  const [sessionId] = useState(() => 
    localStorage.getItem('roastBattleSessionId') || 
    `rb_${Math.random().toString(36).substring(2, 8)}`
  );
  const [selectionError, setSelectionError] = useState(null);
  const [isSidePanelVisible, setIsSidePanelVisible] = useState(false);
  const [showMainContent, setShowMainContent] = useState(false);
  const [sidePanelMode, setSidePanelMode] = useState(null);
  const [currentTypingPolitician, setCurrentTypingPolitician] = useState(null);

  // Ref for auto-scrolling to the latest message
  const messagesEndRef = useRef(null);

  // Scroll to the bottom of the messages container
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Effect to scroll to bottom whenever posts change
  useEffect(() => {
    scrollToBottom();
  }, [posts]);

  // Handle Side Panel Action
  const handleSidePanelAction = () => {
    // Clear existing state
    setPosts([]);
    setBattleRound(1);
    setBattlePhase(null);
    setPreviousExchanges([]);
    setIsBattleComplete(false);
    setError(null);
    setTopic('');
    setUserInput('');
    setShowMainContent(true);
    setIsSidePanelVisible(false);
    
    // Set mode based on number of selected politicians
    if (selectedPoliticians.length === 1) {
      setIsChatMode(true);
    } else if (selectedPoliticians.length === 2) {
      setIsChatMode(false);
    }
  };

  // Effects
  useEffect(() => {
    if (selectedPoliticians.length === 1) {
      setIsChatMode(true);
    } else if (selectedPoliticians.length === 2) {
      setIsChatMode(false);
      setBattleRound(1);
      setBattlePhase(null);
      setPreviousExchanges([]);
    }
    setPosts([]);
    setSelectionError(null);
    setIsBattleComplete(false);
  }, [selectedPoliticians]);

  useEffect(() => {
    localStorage.setItem('roastBattleSessionId', sessionId);
  }, [sessionId]);

  // Effect to handle body scroll when side panel is open
  useEffect(() => {
    if (isSidePanelVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }
    return () => {
      document.body.style.overflow = 'visible';
    };
  }, [isSidePanelVisible]);

  // Handle closing side panel on window resize
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1024) {
        setIsSidePanelVisible(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Data constants
  const trendingTopics = [
    { id: 'economy', label: 'Economy 📈' },
    { id: 'taxes', label: 'Taxes 💰' },
    { id: 'abortion', label: 'Abortion 🏥' },
    { id: 'immigration', label: 'Immigration 🌎' },
    { id: 'nato', label: 'NATO & Ukraine Aid 🌍' },
    { id: 'gaza', label: 'Israel-Gaza War ⚔️' },
    { id: 'healthcare', label: 'Healthcare 🏥' },
    { id: 'crime', label: 'Crime 🚔' }
  ];

  const politicians = {
    trump: {
      name: 'Donald J. Trump',
      handle: 'realDonaldTrump',
      style: 'Confident, bold, and assertive tone with simple language.',
      avatar: '/avatars/trump.jpg',
    },
    harris: {
      name: 'Kamala Harris',
      handle: 'KamalaHarris',
      style: 'Professional, focused on policy, with a determined tone.',
      avatar: '/avatars/harris.jpg',
    },
    obama: {
      name: 'Barack Obama',
      handle: 'BarackObama',
      style: 'Calm, intellectual, slight sass.',
      avatar: '/avatars/obama.jpg',
    },
    biden: {
      name: 'Joe Biden',
      handle: 'JoeBiden',
      style: 'Folksy, determined, optimistic.',
      avatar: '/avatars/biden.jpg',
    },
    clinton: {
      name: 'Hillary Clinton',
      handle: 'HillaryClinton',
      style: 'Sharp, policy-focused, and occasionally witty.',
      avatar: '/avatars/clinton.jpg',
    },
  };

  // Topic selection handler
  const handleTopicSelect = async (topicId) => {
    const isBattleInProgress = selectedPoliticians.length === 2 && !isBattleComplete && (isLoading || posts.length > 0);
    
    if (isBattleInProgress) return;
  
    const selected = trendingTopics.find(t => t.id === topicId);
    if (selected) {
      if (posts.length > 0 || isLoading) {
        setPosts([]);
        setBattleRound(1);
        setBattlePhase(null);
        setPreviousExchanges([]);
        setIsLoading(false);
        
        try {
          await fetch('/api/clearThread', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ sessionId, topic: selectedTopic }),
          });
        } catch (error) {
          console.error('Error clearing previous thread:', error);
        }
      }
      
      const newTopic = topicId === selectedTopic ? '' : selected.label.split(' ')[0];
      setSelectedTopic(topicId === selectedTopic ? null : topicId);
      setTopic(newTopic);
      setUserInput(newTopic);
      setIsSidePanelVisible(false); // Close side panel after selection
    }
  };

  // Metrics generation
  const generateMetrics = (roundNumber = 1) => {
    const multiplier = Math.min(roundNumber / 2, 5);
    return {
      replies: Math.floor((Math.random() * 10000 + 1000) * multiplier),
      reposts: Math.floor((Math.random() * 50000 + 5000) * multiplier),
      likes: Math.floor((Math.random() * 100000 + 10000) * multiplier),
      views: Math.floor((Math.random() * 1000000 + 100000) * multiplier),
    };
  };

  // User post creation
  const createUserPost = (text, replyTo = null) => ({
    id: Date.now() + Math.random(),
    name: 'You',
    handle: 'user',
    avatar: '/avatars/default-user.jpg',
    text,
    metrics: generateMetrics(),
    replyTo,
    time: 'Just now',
    isUser: true
  });

  // Post Component
  const Post = ({ post }) => {
    const [metrics, setMetrics] = useState(post.metrics);
    const [hasLiked, setHasLiked] = useState(false);
    const [hasReposted, setHasReposted] = useState(false);
    const [isBookmarked, setIsBookmarked] = useState(false);
  
    const handleLike = () => {
      const multiplier = hasLiked ? -1 : 1;
      setMetrics(prev => ({
        ...prev,
        likes: prev.likes + (10000 * multiplier),
        reposts: prev.reposts + (5000 * multiplier),
      }));
      setHasLiked(!hasLiked);
    };
  
    if (isChatMode) {
      return (
        <div className={`chat-message ${post.isUser ? 'user-message' : 'politician-message'}`}>
          <img src={post.avatar} alt="" className="avatar" />
          <div className="post-content">
            <div className="post-body">
              <div className="post-header">
                <span className="post-name">{post.name}</span>
                {!post.isUser && <span className="verified">✓</span>}
                <span className="post-time">· {post.time}</span>
              </div>
              <div className="post-text">{post.text}</div>
            </div>
          </div>
        </div>
      );
    }
  
    return (
      <div className="post">
        <div className="post-content">
          <img src={post.avatar} alt="" className="avatar" />
          <div className="post-body">
            <div className="post-header">
              <span className="post-name">{post.name}</span>
              {!post.isUser && <span className="verified">✓</span>}
              <span className="post-handle">@{post.handle}</span>
              <span className="post-time">· {post.time}</span>
            </div>

            {post.replyTo && (
              <div className="replying-to">Replying to @{post.replyTo}</div>
            )}

            <div className="post-text">{post.text}</div>

            <div className="post-actions">
              <button className="action-button">
                💬 {metrics.replies.toLocaleString()}
              </button>
              <button
                onClick={() => setHasReposted(!hasReposted)}
                className={`action-button ${hasReposted ? 'active' : ''}`}
              >
                🔁 {metrics.reposts.toLocaleString()}
              </button>
              <button
                onClick={handleLike}
                className={`action-button ${hasLiked ? 'active' : ''}`}
              >
                ❤️ {metrics.likes.toLocaleString()}
              </button>
              <button
                onClick={() => setIsBookmarked(!isBookmarked)}
                className={`action-button ${isBookmarked ? 'active' : ''}`}
              >
                🔖 {metrics.views.toLocaleString()}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // Handle Politician Selection
  const handlePoliticianSelect = (key) => {
    setSelectedPoliticians(prev => {
      if (prev.includes(key)) {
        const newSelection = prev.filter(p => p !== key);
        // Update sidePanelMode based on selection
        if (newSelection.length === 1) {
          setSidePanelMode('chat');
        } else if (newSelection.length === 2) {
          setSidePanelMode('battle');
        } else {
          setSidePanelMode(null);
        }
        // Reset to welcome screen if no politicians selected
        if (newSelection.length === 0) {
          setShowMainContent(false);
        }
        return newSelection;
      }
      
      if (prev.length >= 2) {
        setSelectionError("You can only select up to 2 politicians at a time");
        return prev;
      }
      
      const newSelection = [...prev, key];
      // Update sidePanelMode based on selection
      if (newSelection.length === 1) {
        setSidePanelMode('chat');
      } else if (newSelection.length === 2) {
        setSidePanelMode('battle');
      }
      setSelectionError(null);
      return newSelection;
    });
  };

  // Handle Start Action
  const handleStartAction = () => {
    setShowMainContent(true);
  };

  // SidePanel Component
  const SidePanel = () => {
    const isBattleMode = selectedPoliticians.length === 2;
    const isBattleInProgress = isBattleMode && !isBattleComplete && (isLoading || posts.length > 0);
    const currentPolitician = selectedPoliticians[0];
    
    return (
      <>
        <div 
          className={`overlay ${isSidePanelVisible ? 'visible' : ''}`}
          onClick={() => setIsSidePanelVisible(false)}
        />
        <div className={`side-panel ${isLoading ? 'loading' : ''} ${isSidePanelVisible ? 'visible' : ''}`}>
          <button 
            className="close-menu-button"
            onClick={() => setIsSidePanelVisible(false)}
          >
            ✕
          </button>
          
          {/* Battle status section */}
          {isBattleMode && isBattleInProgress && (
            <div className="battle-status">
              <div className="battle-progress">
                Round {Math.ceil(battleRound/2)} of {ROUNDS_DISPLAY}
              </div>
              <div className="battle-intensity">
                {battlePhase?.intensity || 'Battle in Progress'}
              </div>
            </div>
          )}

          {/* Battle complete section */}
          {isBattleComplete && (
            <div className="battle-complete-status">
              <div className="battle-complete-message">
                Battle Complete! 🏆
              </div>
              <div className="battle-complete-hint">
                Select new politicians or topic for another battle!
              </div>
            </div>
          )}
    
          {/* Trending topics section */}
          <div className="trending-topics">
            <h2>Trending Topics 🔥</h2>
            <div className="topic-buttons">
              {trendingTopics.map((topic) => (
                <button
                  key={topic.id}
                  onClick={() => handleTopicSelect(topic.id)}
                  className={`topic-button ${
                    selectedTopic === topic.id ? 'selected' : ''
                  } ${isBattleInProgress ? 'disabled' : ''}`}
                  disabled={isBattleInProgress}
                >
                  {topic.label}
                </button>
              ))}
            </div>
          </div>
    
          {/* Politicians section */}
          <div className="active-politicians">
            <h2>Select Politicians</h2>
            {selectionError && (
              <div className="selection-error">
                {selectionError}
              </div>
            )}
            <div className="selection-guide">
              {selectedPoliticians.length === 0 && (
                <div className="guide-text">Select 1 or 2 politicians</div>
              )}
              {selectedPoliticians.length === 1 && (
                <div className="guide-text">1v1 Roast Mode - Start roasting</div>
              )}
              {selectedPoliticians.length === 2 && (
                <div className="guide-text">Battle Mode - Ready for roast battle!</div>
              )}
            </div>
            <div className="politician-buttons">
              {Object.entries(politicians).map(([key, pol]) => (
                <button
                  key={key}
                  onClick={() => handlePoliticianSelect(key)}
                  className={`politician-button ${
                    selectedPoliticians.includes(key) ? 'selected' : ''
                  } ${isBattleInProgress ? 'disabled' : ''}`}
                  disabled={isBattleInProgress}
                >
                  {pol.name.split(' ')[0]}
                </button>
              ))}
            </div>
  
            {/* Start buttons section - Now controlled by sidePanelMode */}
            {sidePanelMode && !isLoading && !isBattleInProgress && (
              <div className="start-section" style={{ marginTop: '20px', textAlign: 'center' }}>
                <button
                  onClick={handleSidePanelAction}
                  className="start-button"
                >
                  {sidePanelMode === 'chat' 
                    ? 'Start 1v1 Roast 💬' 
                    : 'Start Roast Battle 🔥'
                  }
                </button>
              </div>
            )}
          </div>
          
          {/* Loading indicator for chat mode */}
          {isLoading && isChatMode && (
            <div className="chat-progress-message">
              {politicians[currentPolitician].name.split(' ')[0]} is typing...
            </div>
          )}
        </div>
      </>
    );
  };

  // User reply handler
  const handleUserReply = async () => {
    if (!userInput.trim()) return;
    
    setIsLoading(true);
    setError(null);

    try {
      const userPost = createUserPost(userInput);
      setPosts(prevPosts => [...prevPosts, userPost]);
      setUserInput('');

      const politicianKey = selectedPoliticians[0];
      const politicianResponse = await generatePost(
        politicianKey, 
        topic || userInput,
        'user', 
        userInput
      );
      
      if (politicianResponse) {
        setPosts(prevPosts => [...prevPosts, politicianResponse]);
      } else {
        setError("Failed to get response from politician");
      }
    } catch (error) {
      console.error('Error in chat:', error);
      setError("Something went wrong with the conversation");
    } finally {
      setIsLoading(false);
    }
  };

  // Constants
  const MAX_ROUNDS = 6; // 3 rounds × 2 exchanges per round
  const ROUNDS_DISPLAY = 3; // What we show to users
  const MESSAGES_PER_ROUND = 4; // 2 exchanges (4 messages) per round
  const READING_TIME_PER_CHAR = 10;
  const MIN_DELAY = 1000;
  const MAX_DELAY = 2000;

  // Calculate reading time based on text length
  const calculateReadingTime = (text) => {
    const time = text.length * READING_TIME_PER_CHAR;
    return Math.min(Math.max(time, MIN_DELAY), MAX_DELAY);
  };

  // Start Battle Handler
  const startBattle = async () => {
    const currentInput = isChatMode ? userInput : topic;
    if (!currentInput.trim()) return;
    
    setIsLoading(true);
    setError(null);
    setBattleRound(1);
    setPreviousExchanges([]);
    setIsBattleComplete(false);
    
    try {
      await fetch('/api/clearThread', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ sessionId, topic: currentInput }),
      });

      if (isChatMode) {
        const userPost = createUserPost(currentInput);
        setPosts([userPost]);
        setUserInput('');

        const politicianKey = selectedPoliticians[0];
        setCurrentTypingPolitician(politicianKey);
        const politicianResponse = await generatePost(
          politicianKey,
          currentInput,
          'user',
          currentInput
        );

        if (politicianResponse) {
          setPosts(prevPosts => [...prevPosts, politicianResponse]);
        }
        setCurrentTypingPolitician(null);
      } else {
        setPosts([]);
        let currentRound = 1;
        const shuffledPoliticians = [...selectedPoliticians].sort(() => Math.random() - 0.5);
        const battle = [];
        
        while (currentRound <= MAX_ROUNDS) {
          const displayRound = Math.ceil(currentRound / 2);
          const messageCount = battle.length % MESSAGES_PER_ROUND + 1;

          for (let i = 0; i < shuffledPoliticians.length; i++) {
            const currentPolitician = shuffledPoliticians[i];
            const nextPolitician = shuffledPoliticians[(i + 1) % shuffledPoliticians.length];
            
            try {
              setCurrentTypingPolitician(currentPolitician);
              const replyTo = battle.length > 0 ? battle[battle.length - 1]?.handle : null;
              
              const roundContext = {
                currentRound: displayRound,
                messageInRound: messageCount,
                isRoundStart: messageCount === 1,
                isRoundEnd: messageCount === MESSAGES_PER_ROUND,
                totalRounds: ROUNDS_DISPLAY
              };

              const post = await generatePost(
                currentPolitician,
                currentInput,
                replyTo,
                null,
                false,
                displayRound,
                battle.map(p => p.text),
                roundContext
              );

              if (post) {
                battle.push(post);
                setPosts(prevPosts => [...prevPosts, post]);
                setPreviousExchanges(prev => [...prev, post.text]);
                
                const readingTime = calculateReadingTime(post.text);
                
                setTimeout(() => {
                  scrollToBottom();
                }, 100);

                const isLastMessage = battle.length === MAX_ROUNDS * 2;
                if (!isLastMessage) {
                  setCurrentTypingPolitician(nextPolitician);
                }

                await new Promise(resolve => setTimeout(resolve, readingTime));
              }
            } catch (err) {
              console.error('Error in battle sequence:', err);
              setError(`Error in battle round ${displayRound}`);
              setCurrentTypingPolitician(null);
              return;
            }
            
            setBattleRound(currentRound);
          }
          currentRound++;
        }
        
        setIsBattleComplete(true);
        setCurrentTypingPolitician(null);
      }
    } catch (error) {
      console.error('Error starting conversation:', error);
      setError("Failed to start the conversation");
      setCurrentTypingPolitician(null);
    } finally {
      setIsLoading(false);
    }
  };

  // Generate Post Handler
  const generatePost = async (
    politicianKey, 
    topic, 
    replyTo = null, 
    userMessage = null, 
    isUserReply = false,
    roundNumber = 1,
    previousResponses = [],
    roundContext = {}
  ) => {
    try {
      const response = await fetch('/api/generatePost', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          topic, 
          politicianKey, 
          replyTo,
          sessionId,
          isUserReply: replyTo === 'user',
          userMessage,
          roundNumber,
          previousResponses,
          roundContext
        }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      
      if (!data || !data.tweet) {
        throw new Error('Invalid response from server');
      }
  
      const pol = politicians[politicianKey];
      
      // Store battle phase information if provided
      if (data.battlePhase) {
        setBattlePhase(data.battlePhase);
      }
      
      return {
        id: Date.now() + Math.random(),
        name: pol.name,
        handle: pol.handle,
        avatar: pol.avatar,
        text: data.tweet,
        metrics: generateMetrics(roundNumber),
        replyTo,
        time: 'Just now',
        battlePhase: data.battlePhase,
        roundNumber
      };
    } catch (error) {
      console.error('Error generating post:', error);
      setError(`Failed to generate post for ${politicians[politicianKey].name}`);
      return null;
    }
  };

  // Main Render
  return (
    <div className="app-container">
      <div className="main-content">
        <header className="app-header">
          <button 
            className="menu-button"
            onClick={() => setIsSidePanelVisible(!isSidePanelVisible)}
            aria-label="Toggle menu"
          >
            ☰
          </button>
          <h1>
            {selectedPoliticians.length === 0 ? 'Political Roasting Arena ⚔️' :
             selectedPoliticians.length === 1 ? '⚡ 1v1 Roast Mode' : 
             'Politician Roast Battle 🔥'}
          </h1>
        </header>

        {(!showMainContent || selectedPoliticians.length === 0) ? (
          <div className="welcome-page">
            <div className="welcome-header">
              <div className="welcome-title">
              Stage Is Hot 💥
              </div>
              <div className="welcome-description">
                Select one politician to enter 1v1 Roast Mode, or two politicians for a Roast Battle!
              </div>
            </div>

            <PoliticianSelector
              politicians={politicians}
              selectedPoliticians={selectedPoliticians}
              onSelect={handlePoliticianSelect}
              selectionError={selectionError}
            />

            {selectedPoliticians.length > 0 && (
              <div className="start-section">
                <button
                  onClick={handleStartAction}
                  className="start-button"
                >
                  {selectedPoliticians.length === 1 
                    ? 'Start 1v1 Chat 💬' 
                    : 'Start Roast Battle 🔥'
                  }
                </button>
              </div>
            )}
          </div>
        ) : (
          <>
            {isChatMode ? (
              <div className="chat-container">
                <div className="messages-container">
                  {posts && posts.length > 0 && posts.map((post) => (
                    <Post key={post.id} post={post} />
                  ))}
                  
                  {/* Ref for auto-scrolling */}
                  <div ref={messagesEndRef} />

                  {isLoading && (
                    <div className="loading-indicator">
                      <div className="loading-text">
                        {politicians[selectedPoliticians[0]].name.split(' ')[0]} is typing...
                      </div>
                    </div>
                  )}

                  {!isLoading && !error && posts.length === 0 && (
                    <div className="no-posts">
                      <div className="no-posts-title">
                        💬 Bring it On
                      </div>
                      <div className="no-posts-text">
                        Enter a message to start chatting with {politicians[selectedPoliticians[0]].name}!
                      </div>
                    </div>
                  )}
                </div>

                {/* Fixed Footer for Chat Mode */}
                <div className={`chat-input-container ${isChatMode ? 'fixed-footer' : ''}`}>
                  <input
                    type="text"
                    value={userInput}
                    onChange={(e) => setUserInput(e.target.value)}
                    placeholder="Type your message..."
                    disabled={isLoading}
                    maxLength={300}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter' && !isLoading && userInput.trim()) {
                        posts.length > 0 ? handleUserReply() : startBattle();
                      }
                    }}
                  />
                  <button
                    onClick={posts.length > 0 ? handleUserReply : startBattle}
                    disabled={isLoading || !userInput.trim()}
                    className="start-button"
                  >
                    Send 💬
                  </button>
                </div>
              </div>
            ) : (
              <>
                <div className="input-section">
                  <input
                    type="text"
                    value={topic}
                    onChange={(e) => setTopic(e.target.value)}
                    placeholder="Enter a topic.."
                    disabled={isLoading || selectedPoliticians.length !== 2}
                    maxLength={100}
                  />
                  <button
                    onClick={startBattle}
                    disabled={isLoading || !topic.trim() || selectedPoliticians.length !== 2}
                    className="start-button"
                  >
                    Start Battle 🔥
                  </button>
                </div>

                {error && (
                  <div className="error-message">
                    {error}
                  </div>
                )}

                {posts && posts.length > 0 && posts.map((post) => (
                  post && <Post key={post.id} post={post} />
                ))}

                {isLoading && (
                  <div className="loading-indicator">
                    <div className="loading-text">
                      Round {Math.ceil(battleRound/2)} of {ROUNDS_DISPLAY} - {
                        currentTypingPolitician ? 
                          politicians[currentTypingPolitician].name.split(' ')[0] : 
                          'Politicians'
                      } is typing...
                    </div>
                  </div>
                )}

                {!isLoading && !error && posts.length === 0 && (
                  <div className="no-posts">
                    <div className="no-posts-title">
                      🎭 Start a Political Roast Battle
                    </div>
                    <div className="no-posts-text">
                      You've selected 2 politicians! Enter a topic to start the roast battle.
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>

      <SidePanel />
    </div>
  );
}

export default App;